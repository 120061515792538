/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/prop-types */
import { useState, cloneElement } from 'react';

import Link from 'next/link';

import { AppBar, Button, List, ListItem, Toolbar, useScrollTrigger } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Image, DarkModeToggler } from '@/src/components/atoms';

import Personas from '../Personas';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    color: theme.palette.divider,
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.action.active,
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 147,
    height: 28,
    [theme.breakpoints.up('sm')]: {
      width: 168,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    minWidth: 150,
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  learnMore: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  appBar: {
    backgroundColor: theme.palette.background.appBar,
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    backdropFilter: 'blur(15px) saturate(160%) contrast(45%) brightness(140%)',
    '-webkit-backdrop-filter': 'blur(20px) saturate(160%) contrast(45%) brightness(140%)',
  },
}));

function ElevationScroll(props) {
  const { children } = props;

  const classes = useStyles();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: trigger ? classes.appBar : undefined,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Topbar = props => {
  const {
    themeMode,
    themeToggler,
    // onSidebarOpen,
    // pages,
    ...rest
  } = props || {};
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [personas, setPersonas] = useState(false);

  // const handleClick = (event, popoverId) => {
  //   setAnchorEl(event.target);
  //   setOpenedPopoverId(popoverId);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setOpenedPopoverId(null);
  // };

  // const {
  //   // solutions,
  //   // platform,
  //   theCompany,
  // } = pages;

  // const MenuGroup = props => {
  //   const { item } = props;
  //   return (
  //     <List disablePadding>
  //       <ListItem disableGutters>
  //         <Typography variant="body2" color="primary" className={classes.menuGroupTitle}>
  //           {item.groupTitle}
  //         </Typography>
  //       </ListItem>
  //       {item.pages.map(
  //         (page, i) =>
  //           page.href !== '/contact' && (
  //             <ListItem disableGutters key={i.toString()} className={classes.menuGroupItem}>
  //               <Link href={page.href} passHref>
  //                 <Typography
  //                   variant="body1"
  //                   component="a"
  //                   className={clsx(classes.navLink, 'submenu-item')}
  //                   color="textSecondary"
  //                   onClick={handleClose}>
  //                   {page.title}
  //                 </Typography>
  //               </Link>
  //             </ListItem>
  //           ),
  //       )}
  //     </List>
  //   );
  // };

  // const SolutionsPages = () => {
  //   const { institutions, sellers, customers, extra } = solutions.children;

  //   return (
  //     <div className={classes.menu}>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={institutions} />
  //         <MenuGroup item={extra} />
  //       </div>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={sellers} />
  //         <MenuGroup item={customers} />
  //         <div className={classes.learnMore}>
  //           <Link href="/solutions" passHref>
  //             <LearnMoreLink title="saiba mais" typographyProps={{ variant: 'h6' }} />
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const PlatformPages = () => {
  //   const { onlineSales, customerSupport, payments, integrations, orders, management } =
  //     platform.children;

  //   return (
  //     <div className={classes.menu}>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={onlineSales} />
  //         <MenuGroup item={customerSupport} />
  //       </div>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={payments} />
  //         <MenuGroup item={integrations} />
  //       </div>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={orders} />
  //         <MenuGroup item={management} />
  //         <div className={classes.learnMore}>
  //           <Link href="/platform" passHref>
  //             <LearnMoreLink title="saiba mais" typographyProps={{ variant: 'h6' }} />
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const TheCompanyPages = () => {
  //   const { eskolare, resources, integrations, legal } = theCompany.children;

  //   return (
  //     <div className={classes.menu}>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={eskolare} />
  //         <MenuGroup item={integrations} />
  //       </div>
  //       <div className={classes.menuItem}>
  //         <MenuGroup item={resources} />
  //         <MenuGroup item={legal} />
  //         <div className={classes.learnMore}>
  //           <Link href="/about" passHref>
  //             <LearnMoreLink title="saiba mais" typographyProps={{ variant: 'h6' }} />
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderPages = {
  //   // solutions: <SolutionsPages />,
  //   // platform: <PlatformPages />,
  //   theCompany: <TheCompanyPages />,
  // };

  return (
    <div>
      <ElevationScroll>
        <AppBar color="transparent">
          <Toolbar disableGutters className={classes.toolbar} {...rest}>
            <div className={classes.logoContainer}>
              <Link href="/">
                <a title="Eskolare">
                  <Image
                    className={classes.logoImage}
                    src={
                      themeMode === 'light'
                        ? '/logo/horizontal-color.svg'
                        : '/logo/horizontal-white.svg'
                    }
                    alt="Eskolare"
                    lazy={false}
                  />
                </a>
              </Link>
            </div>
            <div className={classes.flexGrow} />
            <List disablePadding className={classes.navigationContainer}>
              {/* {[
                // solutions,
                // platform,
                theCompany,
              ].map(page => (
                <div key={page.id}>
                  <ListItem
                    aria-describedby={page.id}
                    onClick={e => handleClick(e, page.id)}
                    className={clsx(
                      classes.listItem,
                      openedPopoverId === page.id ? classes.listItemActive : '',
                    )}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={clsx(classes.listItemText, 'menu-item')}>
                      {page.title}
                    </Typography>
                    <ListItemIcon className={classes.listItemIcon}>
                      <ExpandMoreIcon
                        className={openedPopoverId === page.id ? classes.expandOpen : ''}
                        fontSize="small"
                      />
                    </ListItemIcon>
                  </ListItem>
                  <Popover
                    elevation={1}
                    id={page.id}
                    open={openedPopoverId === page.id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    classes={{ paper: classes.popover }}>
                    <div>{renderPages[page.id]}</div>
                  </Popover>
                </div>
              ))} */}
              <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
                <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
              </ListItem>
              <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.listItemButton}
                  aria-label="Entrar na plataforma"
                  onClick={() => setPersonas(true)}>
                  Entrar
                </Button>
                <Personas open={personas} onClose={() => setPersonas(false)} />
              </ListItem>
            </List>
            {/* <Hidden mdUp>
              <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
              <IconButton className={classes.iconButton} onClick={onSidebarOpen} aria-label="Menu">
                <MenuIcon />
              </IconButton>
            </Hidden> */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  );
};

Topbar.propTypes = {
  // onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
