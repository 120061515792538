import {
  List,
  ListItem,
  ListItemAvatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  NoSsr,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  dialog: {
    borderTop: `8px transparent solid`,
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(2),
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
  dialogContent: {
    padding: 0,
  },
  familiesAvatar: {
    backgroundColor: theme.palette.branding.red.main,
  },
  institutionsAvatar: {
    backgroundColor: theme.palette.branding.blue.main,
  },
  sellersAvatar: {
    backgroundColor: theme.palette.branding.yellow.main,
  },
}));

function Personas(props) {
  const { open, onClose, className } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      PaperProps={{ className: clsx(className, classes.dialog) }}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Escolha seu perfil</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <List>
          {/* <ListItem button component="a" divider href="/families">
            <ListItemAvatar>
              <Avatar className={classes.familiesAvatar}>
                <NoSsr>
                  <i className="fas fa-user-graduate" />
                </NoSsr>
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary="Vou comprar ou acompanhar algum pedido já feito">
              Sou consumidor
            </ListItemText>
            <NavigateNextIcon color="action" />
          </ListItem> */}
          <ListItem button component="a" divider href="/institutions">
            <ListItemAvatar>
              <Avatar className={classes.institutionsAvatar}>
                <NoSsr>
                  <i className="fas fa-school" />
                </NoSsr>
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary="Preciso fazer gestão do meu canal de venda">
              Sou instituição
            </ListItemText>
            <NavigateNextIcon color="action" />
          </ListItem>
          <ListItem button component="a" href="/sellers">
            <ListItemAvatar>
              <Avatar className={classes.sellersAvatar}>
                <NoSsr>
                  <i className="fas fa-building" />
                </NoSsr>
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary="Quero gerenciar minhas vendas na plataforma">
              Sou fornecedor
            </ListItemText>
            <NavigateNextIcon color="action" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" className={classes.button}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Personas.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Personas;
